import PropTypes from "prop-types"; 

function ImageQuizPanel({ question, onSelect, selection }) {
   const { options, id } = question;
  
 
 
  return (
    <div className="quiz-wrapper">
      <h5>
        {question?.subtitle && question?.subtitle}
        &nbsp; {question?.question_text}
      </h5>
      <div className="options-wrapper">
        {options?.length > 0 &&
          options.map((option) => (
            <div
              className={`quiz-item ${
                selection?.answer_id === option?.option_id && "selected"
              }`}
              key={option?.option_id}
              onClick={() => onSelect({ id: id, answer_id: option?.option_id })}
            >
              {/* {loading[i] === true && <div className="img-skeleton"></div>} */}
              <img
                src={"/quiz_images" + option.option_image}
                // style={{ display: loading[i] ? "none" : "block" }}
                alt=""
              />
              <div className="image-label">{option?.option_text}</div>
            </div>
          ))}
      </div>
    </div>
  );
}

ImageQuizPanel.propTypes = {
  question: PropTypes.object,
  onSelect: PropTypes.func,
  selection: PropTypes.object
};

export default ImageQuizPanel;