import { useState, useEffect, useRef } from "react";
import {  useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Spinner from "./Spinner";
// import footerImg from "../assets/mask_group.png";
import quizlogo from "../assets/quiz_logo.png";
 import { preloadImages } from "../utils";

 const imageUrls = [
   "../quiz_images/q1o1.jpg",
   "../quiz_images/q1o2.jpg",
   "../quiz_images/q1o3.jpg",
   "../quiz_images/q1o4.jpg",
   "../quiz_images/q2o1.jpg",
   "../quiz_images/q2o2.jpg",
   "../quiz_images/q2o3.jpg",
   "../quiz_images/q2o4.jpg",
   "../quiz_images/q3o1.jpg",
   "../quiz_images/q3o2.jpg",
   "../quiz_images/q3o3.jpg",
   "../quiz_images/q3o4.jpg",
   "../quiz_images/q11o1.jpg",
   "../quiz_images/q11o2.jpg",
   "../quiz_images/q11o3.jpg",
   "../quiz_images/q11o4.jpg",
   "../quiz_images/q5o1.jpg",
   "../quiz_images/q5o2.jpg",
   "../quiz_images/q5o3.jpg",
   "../quiz_images/q5o4.jpg",
   "../quiz_images/q6o1.jpg",
   "../quiz_images/q6o2.jpg",
   "../quiz_images/q6o3.jpg",
   "../quiz_images/q6o4.jpg",
   "../quiz_images/q11o1.jpg",
   "../quiz_images/q11o2.jpg",
   "../quiz_images/q11o3.jpg",
   "../quiz_images/q12o1.jpg",
   "../quiz_images/q12o2.jpg",
   "../quiz_images/q12o3.jpg",
   "../quiz_images/q12o4.jpg",
   "../quiz_images/q13o1.jpg",
   "../quiz_images/q13o2.jpg",
   "../quiz_images/q13o3.jpg",
 ];





const Registration = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [loginMode, setLoginMode] = useState(false);
  const [registerMode, setRegisterMode] = useState(true);
  const [otpConfirm,setOtpConfirm] = useState(false);
    const [formVal, setFormVal] = useState({
      name: "",
      mobile: "",
      email: "",
      otp: ""
    });
    const [errors, setErrors] = useState({});
  const urlRef = useRef("register");

  useEffect(() => {
    window.sessionStorage.clear();
  preloadImages(imageUrls);
  },[])

  useEffect(() =>{
      if (otpConfirm) {
        urlRef.current = "verify";
        setFormVal((state) => ({
          ...state,
          mobile: window.sessionStorage.getItem("mobile") ?? formVal.mobile
        }));
      } else if (!otpConfirm && loginMode) {
        urlRef.current = "login/mobile";
      } else {
        urlRef.current = "register";
      }

  },[loginMode,otpConfirm])



  const handleOnChange = (e) => {
    setErrors({})
    setFormVal((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };
  

  
 const handleSubmit = async (e) => {
   e.preventDefault();
   setLoading(true);
   setErrors({});
 
   try {
     const response = await fetch(
       `${import.meta.env.VITE_SERVER_URL}api/${urlRef.current}`,
       {
         mode: "cors",
         method: "POST",
         headers: {
           "Content-Type": "application/json",
           Accept: "application/json",
           "X-Requested-With": "XMLHttpRequest",
           "api-key": import.meta.env.VITE_API_KEY
         },
         body: JSON.stringify(formVal)
       }
     );

     const statusCode = response.status;
     const resp = await response.json();

     switch (statusCode) {
       case 200:
         if (resp.status === 3422) {
           //otp success
           setOtpConfirm(false);
           setLoginMode(false);
           setRegisterMode(false);
           localStorage.setItem("user", resp.userId);           
           if(resp.completed == true) {
            navigate("/leaderboard");
           } else{
             navigate("/quiz");
           }
          
         } else if (resp.status === "otp") {
           setOtpConfirm(true);
           setLoginMode(false);
           setRegisterMode(false);

           //dummy
           setFormVal((state) => ({
             ...state,
             otp: resp.otp ?? ""
           }));
           //
           window.sessionStorage.setItem("mobile", formVal.mobile);
           window.sessionStorage.setItem("email", formVal.email);
         } else {
           setErrors({ other: "Server Error" });
         }
         break;
       case 201:
         if (resp.status === 10196) {
           window.sessionStorage.setItem("mobile", formVal.mobile);
            setFormVal((state) => ({
              ...state,
              otp: resp.otp ?? ""
            }));
           setLoginMode(false);
           setRegisterMode(false);
           setOtpConfirm(true);
         }
         break;
       case 422:
        
        //  setRegisterMode(false);
        //  setLoginMode(true);
         setErrors(resp.errors);
         break;
       case 401:
         if (resp.status === 2342) {
           //invalid otp
           setErrors({ other: "Invalid Otp" });
         }
         break;
       case 500:  
       case 404:
         if (resp.status === 4982) {
           setErrors({ other: "OTP Error" });
         }
         else if (resp.status === 499) {
           setErrors({ other: "Email or Mobile Number is wrong" });
         } 
         else {
              setErrors({ other: resp.message });
          }
        
        
     }
   } catch (error) {
     console.log(error);
     setErrors({ other: "Unable to connect" });
   } finally {
     setLoading(false);
   }
 };
 
  return (
    <div className="registration-page">
      <img src={quizlogo} alt="Quiz Logo" className="logo" id="logo" />
      <h5>Enter your basic information</h5>

      <form onSubmit={handleSubmit}>
        <div className="input-wrapper">
          {!otpConfirm && loginMode && (
            <>
              <div
                className={`${
                  errors?.mobile?.length > 0 && !loginMode && "error"
                } input-group`}
              >
                <input
                  required
                  type="tel"
                  name="mobile"
                  placeholder="Your mobile number"
                  value={formVal.mobile}
                  onChange={handleOnChange}
                  disabled={false}
                />

                <div className="error-tip">
                  {!loginMode &&
                    errors?.mobile?.length > 0 &&
                    errors?.mobile.map((err) => (
                      <p className="new-line" key={err}>
                        {err}
                      </p>
                    ))}
                </div>
              </div>
              <div
                className={`${
                  errors?.email?.length > 0 && !loginMode && "error"
                } input-group`}
              >
                <input
                  required
                  type="email"
                  name="email"
                  placeholder="Your email address"
                  value={formVal.email}
                  onChange={handleOnChange}
                  disabled={false}
                />
                <div className="error-tip">
                  {!loginMode &&
                    errors?.mobile?.length > 0 &&
                    errors?.mobile.map((err) => (
                      <p className="new-line" key={err}>
                        {err}
                      </p>
                    ))}
                </div>
              </div>
            </>
          )}
          {otpConfirm && (
            <div className="input-group">
              <input
                type="tel"
                name="otp"
                placeholder="Enter your OTP"
                value={formVal.otp}
                onChange={handleOnChange}
              />
              <span className="info-tip">
              Please check your email inbox or spam folder for the OTP.
              </span>
            </div>
          )}
          {registerMode && (
            <motion.div
              key="modal"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.3 }}
              className="modal"
            >
              <div
                className={`${errors?.name?.length > 0 && "error"} input-group`}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px"
                }}
              >
                <input
                  required
                  type="text"
                  name="name"
                  placeholder="Fullname"
                  value={formVal.name}
                  onChange={handleOnChange}
                  disabled={loginMode}
                />
                <div className="error-tip">
                  {errors?.name?.length > 0 && errors?.name.map((err) => err)}
                </div>
              </div>
              <div
                className={`${
                  errors?.email?.length > 0 && "error"
                } input-group`}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px"
                }}
              >
                <input
                  required
                  type="email"
                  name="email"
                  placeholder="Email ID"
                  value={formVal.email}
                  onChange={handleOnChange}
                  disabled={loginMode}
                />
                <div className="error-tip">
                  {errors?.email?.length > 0 && errors?.email.map((err) => err)}
                </div>
              </div>
              <div
                className={`${
                  errors?.mobile?.length > 0 && "error"
                } input-group`}
              >
                <input
                  required
                  type="tel"
                  name="mobile"
                  placeholder="Mobile number"
                  value={formVal.mobile}
                  onChange={handleOnChange}
                  disabled={loginMode}
                />
                <div className="error-tip">
                  {errors?.mobile?.length > 0 &&
                    errors?.mobile.map((err) => err)}
                </div>
              </div>
            </motion.div>
          )}
          {errors?.other && <div className="error-tip">{errors.other}</div>}
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="button-container">
              <button type="submit" className="btn-cta">
                {!loginMode && !otpConfirm
                  ? "Register"
                  : otpConfirm
                  ? "Verify"
                  : "Login"}
              </button>

              {!loginMode && !otpConfirm && (
                <p>
                  Existing user! {""}
                  <span
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => {
                      setLoginMode(true);
                      setOtpConfirm(false);
                      setRegisterMode(false);
                      setErrors({});
                    }}
                  >
                    Click here{" "}
                  </span>{" "}
                  to login
                </p>
              )}
              {!otpConfirm && loginMode && (
                <p>
                  New user! {""}
                  <span
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => {
                      setLoginMode(false);
                      setOtpConfirm(false);
                      setRegisterMode(true);
                      setErrors({});
                    }}
                  >
                    Click here
                  </span>{" "}
                  to Register
                </p>
              )}
            </div>
          </>
        )}
      </form>

      {/* OTP */}

      {/* <img src={footerImg} id="footer-img" alt="Question" /> */}
    </div>
  );
};

export default Registration;
