 import { Link } from "react-router-dom";
 import quizlogo from "../assets/quiz_logo.png";

const LandingPage = () => {
  return (
    <div className="body-wrapper home-page">
      {/* <h2>X Factor</h2> */}
      <img src={quizlogo} alt="Quiz Logo" id="logo" />
      <p style={{lineHeight: 1.8}}>
        Love to travel? Then we have something just for you. <br/> Rally your friends,
        dive into our fun quiz, and win exciting prizes!
      </p>

      <Link to="/registration">
        <button className="btn-cta">Play Now</button>
      </Link>
    </div>
  );
};

export default LandingPage;
